import React, { useCallback, useState } from 'react'
import styles from './VideoGallery.module.css'
import { Maybe } from '@graphql-tools/utils'
import { When } from 'react-if'
import { EmbeddedVideo } from '@/molecules/EmbeddedVideo'
import { HubpageSingleItemSlider } from '@/molecules/HubpageSingleItemSlider'
import { SimpleThumbnail } from '@/molecules/Thumbnail'
import { AngelPlayerPreloadedThumbnail } from '@/organisms/AngelPlayerPreloadedThumbnail'
import { PlayableLivestream } from '@/services/LivestreamService'
import { FilmDetailsAdditionalVideosCollection } from '@/types/codegen-contentful'
import { useTranslate } from '@/utils/translate/translate-client'

export interface VideoGalleryProps {
  livestreams: PlayableLivestream[] | null
  trailerUrl?: string | null
  trailerTitle: string
  additionalVideos?: Maybe<FilmDetailsAdditionalVideosCollection>
  galleryClassName?: string
  keyArt?: string | null
}

export const VideoGallery: React.FC<VideoGalleryProps> = ({
  livestreams,
  trailerTitle,
  trailerUrl,
  additionalVideos,
  galleryClassName,
  keyArt,
}) => {
  const { t } = useTranslate('theatrical-presales')
  const [loadPlayer, setLoadPlayer] = useState(false)

  const handleLoadPlayer = useCallback(() => {
    setLoadPlayer((prev) => !prev)
  }, [])

  const totalVideos = (livestreams?.length || 0) + (additionalVideos?.items?.length || 0)
  const canShowChevron = (trailerUrl && totalVideos > 0) || totalVideos > 1

  return (
    <>
      {Boolean(trailerUrl || totalVideos > 0) && (
        <HubpageSingleItemSlider
          title={t('trailerAndMore', 'Trailer & More')}
          showChevron={canShowChevron}
          sliderStyles={styles.videoGallery}
          infinite={false}
          isPlayer={true}
          className={galleryClassName}
          id="video-section-title"
        >
          {trailerUrl && (
            <div id="youtube" className="overflow-hidden rounded-2xl">
              <EmbeddedVideo className="w-full" title={trailerTitle} url={trailerUrl} />
            </div>
          )}
          {additionalVideos?.items?.map((video, index) => {
            return video?.link && video.title ? (
              <div key={index} className="overflow-hidden rounded-2xl">
                <When condition={video.link.includes('angel.com/embed')}>
                  {!loadPlayer ? (
                    <SimpleThumbnail
                      imageUrl={video?.thumbnail?.[0]?.secure_url || keyArt || ''}
                      onClick={handleLoadPlayer}
                      className={'rounded-2xl'}
                    />
                  ) : (
                    <EmbeddedVideo className="w-full" title={video.title} url={video.link + '?autoplay=1'} />
                  )}
                </When>
                <When condition={!video.link.includes('angel.com/embed')}>
                  <EmbeddedVideo className="w-full" title={video.title} url={video.link} />
                </When>
              </div>
            ) : null
          })}
          {livestreams?.map((livestream) => {
            return (
              <div key={livestream.guid}>
                <AngelPlayerPreloadedThumbnail video={livestream} />
              </div>
            )
          })}
        </HubpageSingleItemSlider>
      )}
    </>
  )
}
