import { ApolloClient, gql as contentfulGQL } from '@apollo/client'
import { slugs } from '@/constants'
import { fullPublisher } from '@/constants/seo'
import { ContentfulClientOptions, getContentfulClient } from '@/services/ApolloClient'
import { GetProjectParams, ProjectMeta } from '@/services/ProjectsService/types'
import { GetTheatricalPreReleasePageDataQuery } from '@/types/codegen-contentful'
import { useTranslate } from '@/utils/translate/translate-client'
import { CatalogTitle } from '../ContentCatalog'

export type GetTheatricalPreReleasePageDataResult = NonNullable<
  NonNullable<NonNullable<GetTheatricalPreReleasePageDataQuery['projectCollection']>['items']>[number]
> & {
  faqs: GetTheatricalPreReleasePageDataQuery['faqCollection']
}

export const getTheatricalPreReleasePageData = async (
  { slug }: GetProjectParams,
  client: ApolloClient<object>,
  opts: ContentfulClientOptions,
): Promise<GetTheatricalPreReleasePageDataResult> => {
  const contentfulClient = getContentfulClient({
    locale: opts.locale ?? 'en',
    preview: opts.preview,
  })

  let contentfulProjectData
  let contentfulFaqData

  try {
    const { data: contentfulData } = await contentfulClient.query({
      query: GET_THEATRICAL_PRE_RELEASE_PAGE_DATA_QUERY,
      variables: { projectSlug: slug, preview: opts.preview },
      fetchPolicy: 'no-cache',
    })
    contentfulProjectData = contentfulData?.projectCollection?.items?.[0]
    contentfulFaqData = contentfulData?.faqCollection?.items
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Failure to get data from Contentful', JSON.stringify(e))
  }

  return {
    ...contentfulProjectData,
    faqs: contentfulFaqData ?? [],
  }
}

export function isTheChosen(project: string): boolean {
  return project === slugs.theChosen
}

export function isProjectThatsBothSeriesAndMovie(projectMeta: Pick<ProjectMeta, 'slug'>): boolean {
  return (
    projectMeta.slug === slugs.homestead ||
    projectMeta.slug === slugs.truthAndConviction ||
    projectMeta.slug === slugs.truthAndTreason
  )
}

//
// NOTE: this is a temporary operation. We want to break the use of Contentful for this data. We are doing that here.
// In the near future, we change the type for catalogTitle to NonNullable, and we
// don't fallback to a generic title.
export function formatFilmName(catalogTitle?: CatalogTitle): string {
  return catalogTitle?.title || fullPublisher.name
}

export function getCurrentYear() {
  return new Date().getUTCFullYear().toString()
}

interface useMoviesTitleProps {
  filmDetails?: NonNullable<GetTheatricalPreReleasePageDataResult>['filmDetails']
  filmName: string
  inTheaters: boolean
  isStreaming: boolean
  releaseYear?: string | null
  releaseDate?: string | null
}

export function useMoviesTitle({
  filmDetails,
  filmName,
  inTheaters,
  isStreaming,
  releaseYear,
  releaseDate,
}: useMoviesTitleProps) {
  const { t } = useTranslate('theatrical-presales')
  let title

  if (isStreaming) {
    title = t(
      'hubPageNowStreamingTitle',
      '{{filmName}} ({{releaseYear}}) | Official Website | Now Streaming on Angel Studios',
      {
        filmName,
        releaseYear,
      },
    )
  } else if (inTheaters) {
    title = t(
      'hubPageNowPlayingInTheaters',
      `{{filmName}} ({{releaseYear}}) | Official Website | Now Playing In Theaters`,
      {
        filmName,
        releaseYear,
      },
    )
  } else if (filmDetails?.theatricalReleaseDateOverrideText) {
    title = t(
      'hubPageInTheatersDateOverride',
      `{{filmName}} ({{releaseYear}}) | Official Website | In Theaters {{releaseDateOverride}}`,
      {
        filmName,
        releaseDateOverride: filmDetails?.theatricalReleaseDateOverrideText,
        releaseYear,
      },
    )
  } else if (releaseDate && releaseDate < new Date().toISOString()) {
    title = t(
      'hubPageInTheatersDate',
      `{{filmName}} ({{releaseYear}}) | Official Website | In Theaters {{releaseDate}}`,
      {
        filmName,
        releaseDate,
        releaseYear,
      },
    )
  } else if (releaseYear) {
    title = t('hubPageTitle', '{{filmName}} ({{releaseYear}}) | Official Website | Angel Studios', {
      filmName,
      releaseYear,
    })
  } else {
    title = t('hubPageTitleBase', '{{filmName}} | Official Website | Angel Studios', {
      filmName,
    })
  }

  return title
}

interface useSeriesTitleProps {
  filmName: string
  isStreaming: boolean
  releaseYear?: string | null
}

export function useSeriesTitle({ filmName, isStreaming, releaseYear }: useSeriesTitleProps) {
  const { t } = useTranslate('theatrical-presales')
  let title

  if (isStreaming && releaseYear) {
    title = t(
      'hubPageNowStreamingTitle',
      '{{filmName}} ({{releaseYear}}) | Official Website | Now Streaming on Angel Studios',
      {
        filmName,
        releaseYear,
      },
    )
  } else if (releaseYear) {
    title = t('hubPageTitle', '{{filmName}} ({{releaseYear}}) | Official Website | Angel Studios', {
      filmName,
      releaseYear,
    })
  } else {
    title = t('hubPageTitleBase', '{{filmName}} | Official Website | Angel Studios', {
      filmName,
      releaseYear,
    })
  }

  return title
}

interface useDigitalTitleProps {
  filmDetails?: NonNullable<GetTheatricalPreReleasePageDataResult>['filmDetails']
  filmName: string
  inTheaters: boolean
  isStreaming: boolean
  releaseYear?: string | null
  releaseDate?: string | null
}

export function useDigitalTitle({
  filmDetails,
  filmName,
  inTheaters,
  isStreaming,
  releaseYear,
  releaseDate,
}: useDigitalTitleProps) {
  const { t } = useTranslate('theatrical-presales')
  let title

  // Most digital pages will only be created when isStreaming is true, but keeping the rest from the hub pages just in case
  if (isStreaming) {
    title = t(
      'digitalPageNowStreamingTitle',
      '{{filmName}} ({{releaseYear}}) | Official Website | Where To Stream {{filmName}}',
      {
        filmName,
        releaseYear,
      },
    )
  } else if (inTheaters) {
    title = t(
      'hubPageNowPlayingInTheaters',
      `{{filmName}} ({{releaseYear}}) | Official Website | Now Playing In Theaters`,
      {
        filmName,
        releaseYear,
      },
    )
  } else if (filmDetails?.theatricalReleaseDateOverrideText) {
    title = t(
      'hubPageInTheatersDateOverride',
      `{{filmName}} ({{releaseYear}}) | Official Website | In Theaters {{releaseDateOverride}}`,
      {
        filmName,
        releaseDateOverride: filmDetails?.theatricalReleaseDateOverrideText,
        releaseYear,
      },
    )
  } else if (releaseDate) {
    title = t(
      'hubPageInTheatersDate',
      `{{filmName}} ({{releaseYear}}) | Official Website | In Theaters {{releaseDate}}`,
      {
        filmName,
        releaseDate,
        releaseYear,
      },
    )
  } else {
    title = t('hubPageTitle', '{{filmName}} ({{releaseYear}}) | Official Website | Angel Studios', {
      filmName,
      releaseYear,
    })
  }

  return title
}

export const GET_THEATRICAL_PRE_RELEASE_PAGE_DATA_QUERY = contentfulGQL/* GraphQL */ `
  fragment InvestmentBannerItems on InvestmentBanner {
    sys {
      id
      publishedVersion
    }
    mobileBackgroundImageCloudinaryPath
    tabletBackgroundImageCloudinaryPath
    desktopBackgroundImageCloudinaryPath
    gravity
    title
    description
    ctaText
    offeringUrl
    interstitialText
    interstitialButtonText
    trailerYouTubeId
    subsidiaryName
  }

  query getTheatricalPreReleasePageData($projectSlug: String!, $locale: String, $preview: Boolean, $region: String) {
    projectCollection(limit: 1, where: { slug: $projectSlug }, locale: $locale, preview: $preview) {
      items {
        slug
        contentCatalogId
        crowdfundingSlug
        showOverview
        showMetaDescription
        distributionStage
        logo
        name
        thumbnail
        hasPressPage
        hasAmbassadorPage
        heroCta {
          sys {
            id
          }
          type
          emailListId
          buttonText
          guildButtonText
          buttonHref
          guildButtonHref
          description
        }
        investmentBanner {
          ...InvestmentBannerItems
        }
        ctaSquaresCollection(limit: 9) {
          items {
            sys {
              id
            }
            title
            description
            icon
            backgroundImage
            type
            emailListId
            buttonText
            guildButtonText
            buttonHref
            guildButtonHref
            marketingFocus
          }
        }
        filmDetails {
          additionalVideosCollection {
            items {
              link
              title
              thumbnail
            }
          }
          angelInvestors
          brandColor
          filmAssociationLogos
          heroBackgroundVideo
          heroBackgroundImage
          imagesCollection {
            items {
              altText
              item
            }
          }
          livestreamImageUrl
          livestreamDescription
          livestreamButtonText
          livestreamButtonHref
          moviePoster
          releaseYear
          sys {
            id
          }
          theatricalReleaseDate
          theatricalReleaseDateOverrideText
          trailerSourceUrl
          shareOverrideUrl
          willHaveTheatricalRelease
          statsBox {
            sys {
              id
            }
            logoImageUrl
            statsDescription
            guildStatsDescription
          }
        }
        shopifyStore {
          storeUrl
          accessToken
          handle
          storeHref
        }
        rottenTomatoes: criticsCollection(limit: 1, where: { sort: "Rotten Tomatoes" }) {
          items {
            rating
            author
            icon
          }
        }
        audience: criticsCollection(limit: 1, where: { sort: "Audience Scores" }) {
          items {
            rating
            author
            icon
          }
        }
        cinema: criticsCollection(limit: 1, where: { sort: "CinemaScore" }) {
          items {
            rating
            author
            icon
          }
        }
        critics: criticsCollection(where: { sort_exists: false }) {
          items {
            author
            company
            rating
            quote
          }
        }
      }
    }
    faqCollection(
      where: {
        location: "watch"
        project: { slug: $projectSlug }
        OR: [{ regionList_exists: false }, { regionList_contains_all: [$region] }]
      }
      locale: $locale
      preview: $preview
      order: ordinal_ASC
    ) {
      items {
        sys {
          id
        }
        answer {
          json
        }
        ordinal
        question
      }
    }
  }
`
